import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useInstances } from "react-ioc";
import { observer } from "mobx-react-lite";
import debounce from "lodash/debounce";

import Typography from "@components/fondation/Typography/Typography";
import { UserStore } from "@store/auth/user.store";
import Stack from "@components/fondation/Stack/Stack";
import RadioCard from "@components/elements/Radio/RadioCard";
import PerformanceCard, {
    PerformanceCardProps,
} from "@components/elements/Card/PerformanceCard/PerformanceCard";
import { PreferenceRenewableOption } from "@services/firebase/user.service/request.types";
import { getImageUrl } from "@components/elements/Image/collection";
import RadioGroup from "@components/elements/Radio/RadioGroup";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import { withSignUpRedirect } from "@components/hoc/withSignUpRedirect";

const TITLE = "Savings preferences";
const MESSAGE =
    "There are lots of reasons to save on energy. Let us know what matters to you most.";

type Option = {
    name: PreferenceRenewableOption;
} & PerformanceCardProps;

const preferenceOptions: Option[] = [
    {
        name: "very-important",
        title: "I want to use renewable energy",
        description:
            "We'll help you find the best wind and solar energy available in your area.",
        imageSrc: getImageUrl("zero_hassle.png"),
        badgeProps: {
            color: "teal",
            label: "Most Climate Friendly",
        },
    },
    {
        name: "not-important",
        title: "I want to lower my energy bill",
        description:
            "We'll help you find a lower price than your current plan.",
        imageSrc: getImageUrl("save.png"),
        badgeProps: {
            color: "default",
            label: "Best value",
        },
    },
];

const RenewablePreferencesPage: React.FC = observer(() => {
    const [userStore] = useInstances(UserStore);
    const navigate = useNavigate();

    const [preference, setPreference] = useState<string | null>(
        userStore.user?.renewable ?? null,
    );

    useEffect(() => {
        UseRudderStack.INSTANCE.pageEvent("sign-up", "renewable", {
            page_name: "renewable",
            name: "renewable",
            url: window.location.href,
        });
    }, []);

    const handleSubmit = useCallback(
        debounce(async (value: PreferenceRenewableOption) => {
            const error = await userStore.updatePreferences(value);
            if (error) {
                return;
            }
            navigate("../autopilot");
        }, 0),
        [],
    );

    const handleChange = (value: string) => {
        UseRudderStack.INSTANCE.trackEvent(
            "renewable-radio_select-renewable_preference",
            { values: value },
        );
        setPreference(value);
        handleSubmit(value as PreferenceRenewableOption);
    };

    const loading = userStore.isLoading;

    return (
        <Stack spacing={6} alignItems="center">
            <Stack>
                <Typography variant="h3" mb={2}>
                    {TITLE}
                </Typography>
                <Typography
                    variant="body1"
                    sx={(t) => ({
                        color: t.palette.grey[800],
                    })}
                >
                    {MESSAGE}
                </Typography>
            </Stack>

            <RadioGroup name="preferences" value={preference}>
                <Stack spacing={3}>
                    {preferenceOptions.map(({ name, ...rest }) => (
                        <RadioCard
                            value={name}
                            key={name}
                            fullWidth
                            disabled={loading}
                            onClick={() => handleChange(name)}
                        >
                            <PerformanceCard {...rest} />
                        </RadioCard>
                    ))}
                </Stack>
            </RadioGroup>
        </Stack>
    );
});

export default withSignUpRedirect(RenewablePreferencesPage);
