import * as React from "react";
import { observer } from "mobx-react-lite";
import Grid from "@components/fondation/Grid/Grid";
import { useNavigate } from "react-router-dom";

import Typography from "@components/fondation/Typography/Typography";
import Image from "@components/elements/Image/Image";
import Box from "@components/fondation/Box/Box";
import Button from "@components/elements/Button/Button";
import { getImageUrl } from "@components/elements/Image/collection";
import { withSignUpRedirect } from "@components/hoc/withSignUpRedirect";

const TITLE_IMAGE_SRC = getImageUrl("link.png");

const ConnectionFailedPage: React.FC = observer(() => {
    const navigate = useNavigate();

    const onLinkClick = () => navigate(`../link`);

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Grid
                container
                spacing={6}
                justifyContent="center"
                marginY="auto"
                p={4}
            >
                <Grid item xs={12} textAlign="center">
                    <Image src={TITLE_IMAGE_SRC} height={115} />
                </Grid>

                <Grid item xs={12} marginX={10}>
                    <Typography variant="h2" textAlign="center" mb={3}>
                        Sorry, there was a problem verifying your credentials
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={(t) => ({ color: t.palette.grey[800] })}
                        textAlign="center"
                    >
                        Let&lsquo;s link your account manually.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" fullWidth onClick={onLinkClick}>
                        link account manually
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
});

export default withSignUpRedirect(ConnectionFailedPage);
