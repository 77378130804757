import { useCallback, useEffect, type FC } from "react";
import { useInstances } from "react-ioc";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import Grid from "@components/fondation/Grid/Grid";
import Typography from "@components/fondation/Typography/Typography";
import UtilityMeta from "@components/prototypes/utilities/UtilityMeta/UtilityMeta";
import ConnectUtilityForm, {
    FormData,
} from "@components/modules/forms/connect-utility/ConnectForm";
import NavLink from "@components/elements/NavLink/NavLink";
import Box from "@components/fondation/Box/Box";
import Alert from "@components/elements/Alert/Alert";
import AlertTitle from "@components/elements/Alert/AlertTitle";
import { CountdownLoader } from "@components/prototypes/CountdownLoader";
import { trackEvent } from "@model/utils/tracking";
import AppProvider from "@root/AppProvider";
import LinkUtilityStore from "@store/auth/link-utility.store";
import { UtilityStore } from "@store/auth/utilities.store";
import ModalBox from "@components/elements/Modal/ModalBox";
import ContainerBox from "@components/prototypes/utilities/UtilityMeta/ContainerBox";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import { withSignUpRedirect } from "@components/hoc/withSignUpRedirect";

AppProvider.register(LinkUtilityStore);

const ConnectUtilityPage: FC = observer(() => {
    const navigate = useNavigate();
    const [linkUtilityStore, utilityStore] = useInstances(
        LinkUtilityStore,
        UtilityStore,
    );

    useEffect(() => {
        UseRudderStack.INSTANCE.pageEvent("sign-up", "credentials", {
            page_name: "credentials",
            name: "credentials",
            url: window.location.href,
        });
        if (utilityStore.utility && !utilityStore.utility.urjanet) {
            navigate("/enrollment/provider/link");
        }
    }, []);

    const { isLoading, isVerifying, error, isConfigError } = linkUtilityStore;
    const { utilityLogo, utilityShortName } = utilityStore;

    const handleSubmit = useCallback(
        async (form: FormData) => {
            UseRudderStack.INSTANCE.trackEvent(
                "credentials-button_click-continue",
            );
            trackEvent("Link Attempted", "Urjanet");
            const error = await linkUtilityStore.linkUtility(form);
            const isTimeUp = await linkUtilityStore.waitForCountDown();
            if (!isTimeUp) {
                return;
            }

            if (!error) {
                trackEvent("Link Submitted", "Urjanet");
            }

            if (linkUtilityStore.navigateToSuccess) {
                navigate("/enrollment/success");
            }
            if (linkUtilityStore.isVerifying) {
                navigate("/enrollment/verifying");
            }
            if (isConfigError) {
                navigate("/enrollment/provider/link");
            }
            if (linkUtilityStore.navigateToLink) {
                navigate("/enrollment/provider/failed");
            }
        },
        [linkUtilityStore, navigate],
    );

    return (
        <ModalBox>
            <Box>
                <ContainerBox>
                    <UtilityMeta
                        name={utilityShortName}
                        logoSrc={utilityLogo}
                    />
                </ContainerBox>

                <Typography variant="h3" mb={3}>
                    Enter your credentials
                </Typography>
            </Box>

            {isVerifying ? (
                <CountdownLoader />
            ) : (
                <Box>
                    <Grid container spacing={3} alignItems="flex-start">
                        <Grid item xs={12}>
                            <ConnectUtilityForm
                                loading={isLoading}
                                initialValues={linkUtilityStore.credentials}
                                onSubmit={handleSubmit}
                            >
                                {!!error && (
                                    <Alert severity="error">
                                        <AlertTitle>{error.title}</AlertTitle>
                                        {error.message}, or{" "}
                                        <NavLink to="../link">
                                            Link Account Manually
                                        </NavLink>
                                    </Alert>
                                )}
                            </ConnectUtilityForm>
                        </Grid>

                        <Grid item xs={12} textAlign="center">
                            <Typography variant="body2">
                                <NavLink
                                    onClick={() => {
                                        UseRudderStack.INSTANCE.trackEvent(
                                            "credentials-link_click-link_account_manually",
                                        );
                                    }}
                                    to="../link"
                                >
                                    Link Account Manually
                                </NavLink>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </ModalBox>
    );
});

export default withSignUpRedirect(ConnectUtilityPage);
