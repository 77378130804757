import { useEffect } from "react";
import { MIGRATION_REDIRECT_URL } from "@pages/auth/SignUp/WelcomePage";

export const withSignUpRedirect = (
    WrappedComponent: React.ComponentType<any>,
) =>
    function WithSignUpRedirectComponent(props: any) {
        useEffect(() => {
            const queryString = window.location.search;
            const redirectBase =
                MIGRATION_REDIRECT_URL || "https://www.joinarbor.com";
            const redirectUrl = queryString
                ? `${redirectBase}${queryString}`
                : redirectBase;
            window.location.href = redirectUrl;
        }, []);

        return <WrappedComponent {...props} />;
    };
