import { useEffect } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useInstance } from "react-ioc";

import Typography from "@components/fondation/Typography/Typography";
import Image from "@components/elements/Image/Image";
import Button from "@components/elements/Button/Button";
import { getImageUrl } from "@components/elements/Image/collection";
import Stack from "@components/fondation/Stack/Stack";
import NavLink from "@components/elements/NavLink/NavLink";
import { UserStore } from "@store/auth/user.store";
import Loader from "@components/elements/Loader/Loader";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import { withSignUpRedirect } from "@components/hoc/withSignUpRedirect";

const IMAGE_SRC = getImageUrl("switch.png");
const TITLE = "Automate your savings";
const MESSAGE =
    "With Autopilot, you'll never miss out on savings. When we find you a lower rate, we'll automatically secure it for you and share all the details. If you don't want it, you can opt out. Customers who activate Autopilot save an average of $189 more than those who don't.";

const AutopilotPage: React.FC = observer(() => {
    const navigate = useNavigate();
    const userStore = useInstance(UserStore);

    useEffect(() => {
        UseRudderStack.INSTANCE.trackEvent(
            "renewable-radio_select-renewable_preference",
            { values: "not-important" },
        );

        UseRudderStack.INSTANCE.pageEvent("sign-up", "autopilot", {
            page_name: "autopilot",
            name: "autopilot",
            url: window.location.href,
        });
    }, []);

    const handleSubmit = async () => {
        UseRudderStack.INSTANCE.trackEvent("autopilot-button_click-autopilot");
        await userStore.updateAutopilot(true);
        navigate("/enrollment/provider");
    };

    const loading = userStore.isLoading;

    return (
        <Stack alignItems="stretch" mt={10}>
            <Stack alignItems="center" mb={5}>
                <Image src={IMAGE_SRC} width={192} />
            </Stack>

            <Typography variant="h3" textAlign="center" mb={2.5}>
                {TITLE}
            </Typography>

            <Typography
                variant="body1"
                color="grey.800"
                textAlign="center"
                mb={10}
            >
                {MESSAGE}
            </Typography>

            <Stack spacing={5} alignItems="center">
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={loading}
                    endIcon={loading && <Loader />}
                >
                    Yes, Activate autopilot
                </Button>
                <NavLink
                    onClick={() => {
                        UseRudderStack.INSTANCE.trackEvent(
                            "autopilot-link_click-manual",
                        );
                    }}
                    to="/enrollment/provider"
                    disabled={loading}
                >
                    <Typography variant="h6">
                        No thanks, I'll do everything manually
                    </Typography>
                </NavLink>
            </Stack>
        </Stack>
    );
});

export default withSignUpRedirect(AutopilotPage);
